.c-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
    max-width: 480px;
    margin: auto;
    z-index: 1000;
}

.c-modal-overlay {
    content: '';
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
}

.c-modal__inner {
    background-color: #fff;
    padding: 30px;
    border-radius: 4px;
}

.c-modal__selected {
    position: relative;
}

.c-close, .c-modal__selected button {
    cursor: pointer;
    -webkit-appearance: none;
    position: absolute;
    top: 1em;
    right: 1em;
    border: none;
    background-color: transparent;
}

.c-close {
    width: 26px;
    height: 26px;
    background-color: #eee;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0
}

.c-close:hover {
    background-color: #ccc;
}

.c-close span {
    margin-top: -5px;
}

.c-modal__slots {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
}

.c-modal__slots .tsc-timeslot {
    margin: .4em 0 !important;
    flex: 0 0 48%;
}

.c-modal__selected {
    margin-top: 14px;
    background-color: #eee;
    border-radius: 4px;
}

.c-modal__selected button {
    top: 0;
    bottom: 0;
    right: 0;
    width: 90px;
    margin: 0;
    border-radius: 4px;
    background-color: #2e4050;
    color: #fff;
}

.c-modal__selected button:hover {
    background-color: #1b2630;
}

#selectedTimeFormatted {
    border: none;
    background-color: transparent;
    width: 100%;
}